<template>
    <div class="flex justify-start mb-3" v-if="data">
        <img :src="data" width="125px" style="margin: 0 !important;" alt="">
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: String
        }
    }
}
</script>
